<template>
  <section id="dashboard-ecommerce">
    <!-- <b-card>
      <b-row class="match-height">
        <b-col xl="7" md="6">
          <div v-if="this.$store.state.userData.roles == 'administrator'">
            <b-button
              v-for="item in this.$store.state.branches"
              :key="item.id"
              :variant="
                item.id == $store.state.userData.cId
                  ? 'primary'
                  : 'outline-primary'
              "
              class="mr-1"
              style="margin-bottom: 7px"
            >
              {{ item.branch }}
            </b-button>
          </div>
        </b-col>
        <b-col xl="5" md="6">
          <div class="text-right">
            <b-button
              variant="outline-primary"
              class="ml-1"
              style="margin-bottom: 7px"
            >
              This Week
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-1"
              style="margin-bottom: 7px"
            >
              This Month
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-1"
              style="margin-bottom: 7px"
            >
              This Year
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-1"
              style="margin-bottom: 7px"
            >
              Last Year
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card> -->
    <div v-if="loading">
      <b-row align-h="center" v-if="loading">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
    </div>
    <div v-else>
      <b-row class="match-height">
        <b-col lg="4" md="4">
          <ecommerce-earnings-chart
            :data="dash"
            :monthlyData="monthlyData"
            :monthlySeries="monthlySeries"
          />
        </b-col>
        <b-col lg="8" md="8">
          <ecommerce-statistics :data="dash" :otherData="otherData" />
        </b-col>
      </b-row>

      <b-row class="match-height" v-if="showCollection && showDues">
        <b-col lg="6" md="12" cols="12">
          <chartjs-bar-chart :weeklyData="weeklyData" />
        </b-col>
        <!-- <b-col lg="6" md="12" cols="12">
          <chartjs-line-chart :yearlyData="yearlyData" />
        </b-col> -->
        <b-col lg="6" md="12" cols="12">
          <chartjs-line-area-chart :yearlyData="yearlyData" />
        </b-col>
      </b-row>
      <!--  v-if="reportDomain == 'myskoolhpgs'"> -->
      <b-card no-body v-if="false">
        <b-card-header>
          <b-card-title>Student Statistics</b-card-title>
        </b-card-header>
        <b-table
          class=""
          :busy="loading"
          :items="items"
          :fields="fields"
          show-empty
          responsive
          hover
        >
          <template #table-busy>
            <div class="d-flex justify-content-center my-3">
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #cell(campus)="data">
            <b-badge variant="light-primary">
              {{ data.item.campusName }}
            </b-badge>
          </template>
          <template #cell(inquiries)="data">
            <b-badge variant="light-primary">
              {{ data.item.inquiries }}
            </b-badge>
          </template>
          <template #cell(registration)="data">
            <b-badge variant="light-primary">
              {{ data.item.registration }}
            </b-badge>
          </template>
          <template #cell(admission)="data">
            <b-badge variant="light-primary">
              {{ data.item.admission }}
            </b-badge>
          </template>
          <template #cell(received)="data">
            <b-badge variant="light-primary">
              {{ data.item.feeReceived }}
            </b-badge>
          </template>
          <template #cell(left)="data">
            <b-badge variant="light-primary">
              {{ data.item.left }}
            </b-badge>
          </template>
          <template #cell(badDebts)="data">
            <b-badge variant="light-primary">
              {{ data.item.badDebts }}
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </div>

    <!-- <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height">
          <b-col lg="4" md="4">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col lg="4" md="4">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="6">
            <ecommerce-revenue-report :data="data.revenue" />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-meetup :data="data.meetup" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-browser-states />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>

      <b-col lg="4" md="6">
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSpinner,
  BTable,
  BBadge,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import ChartjsBarChart from "@/views/charts-and-maps/charts/chartjs/ChartjsBarChart.vue";
import ChartjsLineChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import ChartjsLineAreaChart from "@/views/charts-and-maps/charts/chartjs/ChartjsLineAreaChart.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BTable,
    BBadge,
    BCardHeader,
    BCardTitle,
    ChartjsLineChart,
    ChartjsLineAreaChart,
    ChartjsBarChart,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      dash: {},
      otherData: {},
      monthlyData: {},
      monthlySeries: [],
      weeklyData: {},
      yearlyData: {},
      loading: true,
      showDues: false,
      showCollection: false,
      currentYear: "",
      rights: {},
      items: [],
      fields: [
        { label: "campus", key: "campus" },
        { label: "inquiries", key: "inquiries" },
        { label: "registration", key: "registration" },
        { label: "admission", key: "admission" },
        { label: "received", key: "received" },
        { label: "left", key: "left" },
        { label: "bad debts", key: "badDebts" },
      ],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadData() {
      this.loading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Users/Dashboard?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log("dash", res);
      this.dash = res.data;
      this.otherData = {
        familyCount: res.familyCount,
        monthly: res.monthly,
      };
      // if (this.dash.collectionChart) {
      //   this.monthlyData = this.dash.collectionChart;
      //   this.monthlySeries = [
      //     this.dash.collectionChart.collection,
      //     this.dash.collectionChart.dues,
      //   ];
      // }
      this.loading = false;
      // this.setData();
    },
    async LoadSummary() {
      let dt = new Date().toJSON().split("T")[0];
      var obj = {
        url:
          this.$store.state.domain +
          "campuses/Summary?db=" +
          this.$store.state.userData.db +
          "&date=" +
          dt,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
    },
    async LoadCollection() {
      var obj = {
        url:
          this.$store.state.domain +
          "Users/DashboardCollection?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
      };
      let collData = await this.get(obj);

      // this.monthlyData = collData.collectionChart;
      // this.monthlySeries = [
      //   collData.collectionChart.collection,
      //   collData.collectionChart.dues,
      // ];

      this.weeklyData = {
        data: collData.collectionGraph,
        highestCollection: collData.highestCollection,
      };

      this.showCollection = true;
    },
    async LoadDues() {
      var obj = {
        url:
          this.$store.state.domain +
          "Users/DashboardDues?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&year=" +
          this.currentYear,
        token: this.$store.state.userData.token,
      };
      let duesData = await this.get(obj);
      this.yearlyData = {
        data: duesData.duesGraph,
        highestDues: duesData.highestDues,
      };
      this.showDues = true;
    },

    setData() {
      this.weeklyData = {
        data: this.dash.collectionGraph,
        highestCollection: this.dash.highestCollection,
      };
      this.yearlyData = {
        data: this.dash.duesGraph,
        highestDues: this.dash.highestDues,
      };
      this.monthlyData = this.dash.collectionChart;
      this.monthlySeries = [
        this.dash.collectionChart.collection,
        this.dash.collectionChart.dues,
      ];
      // console.log(this.monthlySeries);
      this.loading = false;
    },
  },
  created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.currentYear = new Date().getFullYear();
      this.LoadData();
      // if (this.reportDomain == 'myskoolhpgs') this.LoadSummary();
      this.LoadCollection();
      this.LoadDues();
      this.$http.get("/ecommerce/data").then((response) => {
        this.data = response.data;
        // console.log(this.data);

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData();
        this.data.congratulations.name =
          userData.fullName.split(" ")[0] || userData.username;
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
